// Get the code-highlight class element
const codeTag = document.getElementsByClassName("code-highlight");

// Generate the copy-icon SVG
function createCopyIconSVG() {
  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  const copyIcon = document.createElementNS("http://www.w3.org/2000/svg", "path");
  svg.setAttribute("viewBox", "0 0 448 512");
  copyIcon.setAttribute("d", "M208 0H332.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V336c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V48c0-26.5 21.5-48 48-48zM48 128h80v64H64V448H256V416h64v48c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48z");
  svg.appendChild(copyIcon);
  svg.classList.add("copy-icon");
  return svg;
}

// Create a copy button element
const copyButton = document.createElement("div");

// Append the SVG to the button element
copyButton.appendChild(createCopyIconSVG())

// Add the copy-button class to the button element
copyButton.classList.add("copy-button");

// Append the copy button to the code-highlight divs
for (let tag in codeTag) {
  if (codeTag.hasOwnProperty(tag)) {
    let button = copyButton.cloneNode(true)
    codeTag[tag].appendChild(button);
  //   // Add click event listener to the copy button
    button.addEventListener("click", () => {
      let snippet = codeTag[tag]["innerText"].replace(/(\n+$)/g, "");
      try {
        // Copy the selected text to the clipboard
        // document.execCommand("copy");
        navigator.clipboard
          .writeText(snippet)
        // Alert the user that the text has been copied by changing the color via CSS
        button.classList.add("copy-button-copied");
        button.classList.remove("copy-button")
        setTimeout(function(){
          button.classList.remove("copy-button-copied");
          button.classList.add("copy-button")
        }, 2000);
      } catch (err) {
        console.error("Unable to copy text:", err);
      }
    });
  }
}